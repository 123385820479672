<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">
    
    <div class="mt-5">
      <!-- <Breadcrumbs :title="`Detail User`"/> -->
      <Snackbar ref="snackbar"/>

      <v-btn href="javascript:history.back()"
        class="text-capitalize pl-0"
        elevation="0"
        text
        small
        color="#F05326">
        <v-icon left>mdi-chevron-left</v-icon>
        kembali
      </v-btn>
      <div class="title black--text mb-2">Detail User</div>

      <v-card outlined class="box-shadow border-radius pa-0 pb-1">
        <v-card-subtitle>
          Informasi User
          <!-- {{ detail_member }} -->
        </v-card-subtitle>
        <v-card-text>
          <v-row justify="start" align="center">
            <v-col cols="2">
              <v-img v-if="!detail_member.picture" :src="require('@/assets/img/profile_picture.png')">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="#d31145">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img v-else :src="detail_member.picture" class="rounded-circle">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="#d31145">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
            <!-- <v-col>
              <div>
                Kirim Ulang email
              </div>
              <v-btn
                color="#F05326"
                class="text-capitalize mt-2"
                outlined>
                Resending Email
              </v-btn>
            </v-col> -->
          </v-row>

          <v-row>
            <v-col>
              <v-row no-gutters class="py-2">
                <v-col>
                  Nama Lengkap
                </v-col>
                <v-col>
                  {{ detail_member.child_name }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  Email Address
                </v-col>
                <v-col>
                  {{ detail_member.email }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  No Hp
                </v-col>
                <v-col>
                  {{ detail_member.phone != "" ? detail_member.phone : "-" }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  Gender
                </v-col>
                <v-col>
                  {{ detail_member.gender == "F" ? "Perempuan" : "Laki-laki" }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  Umur
                </v-col>
                <v-col>
                  {{ detail_member.age }} tahun
                </v-col>
              </v-row>
            </v-col>
            
            <v-col>
              <v-row no-gutters class="py-2">
                <v-col>
                  Mulai Akses
                </v-col>
                <v-col>
                  log in {{ detail_member.created_at | datetime }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  Last Access
                </v-col>
                <v-col>
                  {{ detail_member.last_login | datetime }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  Periode
                </v-col>
                <v-col>
                  {{ Object.keys(detail_member.meta).length > 0 ? detail_member.meta.periode_name : "-" }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  Durasi
                </v-col>
                <v-col>
                  {{ Object.keys(detail_member.meta).length > 0 ? detail_member.meta.duration + "Days" : "-" }}
                </v-col>
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  Kadaluarsa
                </v-col>
                <v-col>
                  {{ detail_member.subscription.package.expired_at | datetime }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  components: { Breadcrumbs, Snackbar },
  data () { 
    return {
      items: [
        {
          text: 'Daftar User',
          disabled: true,
          href: '/progress',
        },
        {
          text: 'Detail User',
          disabled: true,
          href: '/users',
        }
      ],
      detail_member: {}
    }
  },
  mounted () {
    this.getDetailMember()
  },
  methods: {
    async getDetailMember() {
      await get(`api/v1/member/detail/${this.$route.params.id}`)
      .then((response) => {
        let res = response.data
        this.detail_member = res.data
      })
    },
  }
}
</script>